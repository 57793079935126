const DotLoader = () => {
  return (
    <div
      className="loaderMain"
      style={{
        width: '100%',
        height: '100%',
        maxHeight: '500px',
      }}
    >
      <span className="loader"></span>
    </div>
  );
};

export default DotLoader;
