import styles from '@/styles/Main/Layout/MainHeader.module.scss';

import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Tab,
  Tabs,
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import terminalLogo from '../../../public/assets/logos/bt_logo.svg';

import useAutoSessionExpireDetection from '@/Components/Layout/autoSessionDetection';
import api, { setAuthToken } from '@/services/api';
import { fetcher } from '@/services/fetcher';
import { MobileContext } from '@/utils/DetectMobileContext';
import { ModalContext } from '@/utils/ModalContext';
import { signOut, useSession } from 'next-auth/react';
import DialogModals from '../Modals/DialogModals';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const AuthMainHeader = () => {
  const router = useRouter();
  useAutoSessionExpireDetection();
  const { data: userSession, status } = useSession();
  const { isMobile } = useContext(MobileContext);

  const menuOptions = [
    { title: 'Home', link: '/' },

    { title: 'Post Listing', link: '/post' },
    { title: 'My Profile', link: '/auth/profile' },

    { title: 'My Listings', link: '/mylistings' },
    userSession && userSession.user?.role.includes('ADMIN')
      ? { title: 'Agent Listings', link: '/agents-listings' }
      : { title: '', link: '' },
  ];
  const [value, setValue] = useState('/');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [state, setState] = useState({
    left: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const [fixedButtons, setfixedButtons] = useState();
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <div className={styles.mainHeaderContainer_logo}>
            <Image
              src={terminalLogo}
              alt="BrokerTerminal"
              onClick={() => router.push('/')}
            />
          </div>
        </ListItem>
        <Divider />
        {menuOptions.map(
          (text, index) =>
            text.title && (
              <ListItem key={text.title + String(index)} disablePadding>
                <ListItemButton onClick={() => router.push(text.link)}>
                  {/* <ListItemIcon></ListItemIcon> */}
                  <ListItemText primary={text.title} />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </Box>
  );

  useEffect(() => {
    const filteredOptions = menuOptions.filter(
      (mf) => mf.link && router.pathname.includes(mf.link)
    );
    setValue(
      filteredOptions.length > 1
        ? filteredOptions[1].link
        : filteredOptions[0].link
    );
  }, [router.pathname]);
  const [setauthenticatedToken, setsetauthenticatedToken] = useState(false);
  useEffect(() => {
    if (userSession?.user?.token) {
      setsetauthenticatedToken(true);
      setAuthToken(userSession?.user?.token);
    }
  }, []);

  const { data, error, isLoading } = useSWR(
    setauthenticatedToken &&
      userSession &&
      userSession.user.token !== '' &&
      !userSession.user.role.includes('ADMIN')
      ? `/auth/validate-session-byauth`
      : '',
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  );
  const { openModal } = useContext(ModalContext);
  const handleLogout = async () => {
    //@ts-ignore
    await signOut({ redirect: false });

    await api.get('/auth/logout', {
      headers: { token: userSession?.user.token },
    });
    openModal(
      <DialogModals
        action={() => {
          router.push('/login');
        }}
        title="Session Expired!"
        type="warning"
        message={`Please Login again.`}
      />
    );
  };
  useEffect(() => {
    if (
      error &&
      (status === 'unauthenticated' || userSession?.user.verified) &&
      setauthenticatedToken
    )
      handleLogout();
  }, [data]);

  useEffect(() => {
    if (userSession && userSession.user && !userSession.user.verified) {
      // Cookies.set(
      //   'userdetails',
      //   JSON.stringify({
      //     ...userSession.user,
      //     reraCard: '',
      //     businessCard: '',
      //     budgetExpertise: [],
      //   }),
      //   {
      //     expires: 1,
      //   }
      // );
      router.push('/login/profileupdate');
    }
  }, []);
  return (
    <div className={styles.mainHeaderContainer} id="top">
      <div
        className={styles.mainHeaderContainer_mobileMenu}
        onClick={() => {
          setState({ left: true });
        }}
      >
        <Image
          src={'/assets/icons/menu-button.svg'}
          width={20}
          height={20}
          alt="menu"
          onClick={() => {
            toggleDrawer('left', true);
          }}
        />
      </div>
      <div className={styles.mainHeaderContainer_logo}>
        <Image
          src={terminalLogo}
          alt="BrokerTerminal"
          onClick={() => router.push('/')}
        />
      </div>

      {userSession?.user.verified && (
        <div className={styles.mainHeaderContainer_desktopMenu}>
          <Box
            sx={{
              border: 1,
              borderRadius: '50px',
              overflow: 'hidden',
              borderColor: '#EBEBEB',
              height: '68px',
              display: 'flex',
              padding: '0 10px',
              alignItems: 'center',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="brokerterminal-menu"
              TabIndicatorProps={{
                sx: {
                  transform: 'scale(0.65)',
                },
              }}
            >
              {menuOptions.map(
                (eachMenu, index) =>
                  eachMenu.title && (
                    <Tab
                      style={{ padding: '24px' }}
                      disableRipple
                      value={eachMenu.link}
                      onClick={() => router.push(eachMenu.link)}
                      key={String(index) + eachMenu.title}
                      label={eachMenu.title}
                    />
                  )
              )}
            </Tabs>
          </Box>
        </div>
      )}

      <div
        className={styles.mainHeaderContainer_auth}
        style={{ marginLeft: 'auto' }}
      >
        <button
          className={styles.mainHeaderContainer_auth_login}
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>

      <SwipeableDrawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {list('left')}
      </SwipeableDrawer>
    </div>
  );
};

export default AuthMainHeader;
