import styles from '@/styles/Main/Home/Home.module.scss';
import { useSession } from 'next-auth/react';
import { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import AuthMainHeader from './AuthMainHeader';
import FixedBottomNavigation from './MobileNavigation';
interface LayoutProps {
  children: ReactNode;
}

const AuthorizedLayout = ({ children }: LayoutProps) => {
  const { data, status } = useSession();
  return (
    <div
      className={styles.mainHomecontainer}
      style={{ paddingBottom: '100px' }}
    >
      <AuthMainHeader />
      <div>{children}</div>
      {/* <AuthMainFooter /> */}

      {isMobile && data?.user.verified && <FixedBottomNavigation />}
    </div>
  );
};

export default AuthorizedLayout;
