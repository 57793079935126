import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { Montserrat } from 'next/font/google';
import { createComponents } from './create-components';
import { createShadows } from './create-shadows';

export const montserrat = Montserrat({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Arial', 'sans-serif'],
});

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#d12d34',
    },
    secondary: {
      main: '#3cb288',
    },
    error: {
      main: red.A400,
    },
  },
  //@ts-ignore
  components: createComponents(),
  //@ts-ignore
  shadows: createShadows(),
  typography: {
    fontFamily: montserrat.style.fontFamily,
  },
});

export default theme;
