import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useRouter } from 'next/router';
import { useState } from 'react';

export default function FixedBottomNavigation() {
  const [value, setValue] = useState(0);
  const router = useRouter();

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '99999' }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          onClick={() => router.push('/')}
          label="Home"
          icon={<HomeIcon />}
          style={{ fontSize: '0.75rem' }}
        />
        <BottomNavigationAction
          onClick={() => router.push('/post')}
          label="Post"
          icon={<AddIcon />}
          style={{ fontSize: '0.75rem' }}
        />
        <BottomNavigationAction
          onClick={() => router.push('/search/deals/hot-deals')}
          label="Hot Deals"
          icon={
            <img
              width={30}
              height={30}
              src="/assets/svg/hotdeal.svg"
              alt="hot"
            />
          }
          style={{ fontSize: '0.5rem !important' }}
        />

        <BottomNavigationAction
          onClick={() => router.push('/search/deals/hot-buyers')}
          label="Hot Buyers"
          icon={
            <img
              width={30}
              height={30}
              src="/assets/svg/investor.svg"
              alt="hot"
            />
          }
          style={{ fontSize: '0.75rem' }}
        />
      </BottomNavigation>
    </Paper>
  );
}

interface MessageExample {
  primary: string;
  secondary: string;
  person: string;
}
