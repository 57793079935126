import styles from '@/styles/Modals/dialog.module.scss';
import { useLoading } from '@/utils/LoadingContext';
import { ModalContext } from '@/utils/ModalContext';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSession } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import api from '../../services/api';
import TerminalButton from '../Buttons/TerminalButton';
import DialogModals from './DialogModals';
interface PropertyEnquiryProps {
  rental: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  bed: string;
  locations: string;
  project: string;
  listingid?: string;
  listingtype?: string;
  source?: string;
  user?: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    RealEstateName?: string;
  };
}
const TerminalEnquiry = ({
  rental,
  open,
  setOpen,
  bed,
  locations,
  project,
  user,
  source,
}: PropertyEnquiryProps) => {
  const { openModal } = useContext(ModalContext);
  const { setLoadingState, loading } = useLoading();
  const [message, setMessage] = useState({
    user: user,
    role: 'agent',
    email: '',
    company: '',
    name: '',
    phone: '',
    project: `${project ? project + ', ' : ''}${locations ? locations : ''} `,
    source: source,
    text: `I am interested in inquiring about availability and pricing for ${
      bed ? bed : 'multiple bedrooms'
    } in ${locations ? locations : ''} ${
      project ? ', ' + project : ''
    }. Please provide me with more information and any current promotions or discounts that may apply.

Thank you for your time`,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    setLoadingState(true);
    if (
      message.text !== '' &&
      message.name !== '' &&
      message.email !== '' &&
      message.phone !== ''
    ) {
      const resp = await api.post('/transaction/bt-query', message);
      if (resp.data.message === 'success') {
        setMessage({
          user: user,
          role: 'agent',

          text: `I am interested in inquiring about availability and pricing for ${
            bed ? bed : 'multiple bedrooms'
          } in ${locations ? locations : ''} ${
            project ? ', ' + project : ''
          }. Please provide me with more information and any current promotions or discounts that may apply.

Thank you for your time`,
          name: '',
          company: '',
          email: '',
          phone: '',
          source: source,
          project: `${project ? project + ', ' : ''}${
            locations ? locations : ''
          } `,
        });
        setLoadingState(false);
        openModal(
          <DialogModals
            action={() => {}}
            title="Thank you for your enquiry!"
            type="success"
            message={`We have received your request and one of our representatives will get back to you as soon as possible. If you have any additional questions or concerns, please don't hesitate to reach out to us.

            Best regards,
            Brokerterminal.com`}
          />
        );

        setOpen(false);
      } else {
        setLoadingState(false);
        openModal(
          <DialogModals
            action={() => {}}
            title="Failed!"
            type="error"
            message={`Cannot Submit email query at this moment`}
          />
        );
      }
    } else {
      setLoadingState(false);
      openModal(
        <DialogModals
          action={() => {}}
          title="Empty values!"
          type="warning"
          message={`Please Fill up all details before Submitting the enquiry`}
        />
      );
    }
    setLoadingState(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, role: (event.target as HTMLInputElement).value });
  };
  const { data, status } = useSession();
  useEffect(() => {
    if (status && status === 'authenticated') {
      setMessage({
        user: user,
        role: 'agent',
        text: `I am interested in inquiring about availability and pricing for ${
          bed ? bed : 'multiple bedrooms'
        } in ${locations ? locations : ''} ${
          project ? ', ' + project : ''
        }. Please provide me with more information and any current promotions or discounts that may apply.

Thank you for your time`,
        name: data.user.firstName,
        company: data.user.RealEstateName,
        email: data.user.email,

        phone: data.user.phoneNumber,
        source: source,
        project: `${project ? project + ', ' : ''}${
          locations ? locations : ''
        } `,
      });
    }
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        padding: '15px !important',
        zIndex: loading ? '2 !important' : '1000 !important',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            background: '#fff',
            // background: "#000",
            margin: 0,
            color: '#000',
            borderRadius: '24px',
            maxWidth: '500px',
            padding: '10px', // Set your width here
          },
        },
      }}
    >
      <DialogTitle>
        Express Your Interest {rental ? 'To Rent a Property' : ''}
      </DialogTitle>
      <DialogContent className={styles.enquiryContainer}>
        <div className={styles.enquiryContainer_reviews}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              I am a:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={message.role}
              onChange={handleChange}
            >
              <FormControlLabel
                value="agent"
                control={<Radio />}
                label="Agent"
              />

              <FormControlLabel
                value="buyer"
                control={<Radio />}
                label="Buyer"
              />

              <FormControlLabel
                value="seller"
                control={<Radio />}
                label="Seller"
              />
            </RadioGroup>
          </FormControl>
          <label htmlFor="nameEnquiry">Name</label>
          <input
            autoFocus={false}
            autoComplete="off"
            id="nameEnquiry"
            type="text"
            name="enquiry"
            placeholder="Your Name"
            value={message.name}
            onChange={(e) => setMessage({ ...message, name: e.target.value })}
          />
          <label htmlFor="nameEmail">Email</label>
          <input
            autoFocus={false}
            autoComplete="off"
            id="nameEmail"
            type="email"
            name="email"
            placeholder="Your Email"
            value={message.email}
            onChange={(e) => setMessage({ ...message, email: e.target.value })}
          />
          {message.role === 'agent' && (
            <>
              <label htmlFor="nameCompany">Company</label>
              <input
                autoFocus={false}
                autoComplete="off"
                id="nameCompany"
                type="text"
                name="companyname"
                placeholder="Company Nme"
                value={message.company}
                onChange={(e) =>
                  setMessage({ ...message, company: e.target.value })
                }
              />
            </>
          )}
          <div className={styles.enquiryContainer_phoneinput}>
            <label htmlFor="enquiryPhone">Phone Number</label>
            <PhoneInput
              id="enquiryPhone"
              //   containerClass={styles.enquiryContainer_main}
              inputClass={styles.enquiryContainer_inputClass}
              buttonClass={styles.enquiryContainer_main}
              dropdownClass={styles.enquiryContainer_dropmain}
              //   containerStyle={styles.enquiryContainer_main}
              country={'ae'}
              value={message.phone}
              onChange={(phone) =>
                setMessage({ ...message, phone: String(phone) })
              }
              placeholder="Enter your Number"
              style={{
                borderRadius: '48px',
                padding: '0 20px',
              }}
              defaultCountry="AE"
              countryCallingCodeEditable={false}
            />
          </div>
          <label htmlFor="messageEnquiry">Message</label>
          <textarea
            id="messageEnquiry"
            name="message"
            placeholder="Write you Message Here"
            value={message.text}
            onChange={(e) => setMessage({ ...message, text: e.target.value })}
            rows={4}
          ></textarea>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.enquiryContainer_buttoncancel}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <TerminalButton position="gg" onClick={handleSubmit} label="Submit" />
      </DialogActions>
    </Dialog>
  );
};

export default TerminalEnquiry;
