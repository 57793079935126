import styles from '@/styles/Main/MainAppLoader.module.scss';
import { useLoading } from '@/utils/LoadingContext';
import BtLogoSvg from '../SVG/BtLogoSvg';
import DotLoader from './DotLoader';

interface AppLoaderInterface {
  loading: boolean;
}
const MainAppLoader = () => {
  const { loading } = useLoading();
  return loading ? (
    <div
      className={
        loading ? styles.mainLoaderContainer : styles.mainLoaderContainerHidden
      }
    >
      <div>
        <div className={styles.mainLoaderContainer_logoLoader}>
          <BtLogoSvg />
        </div>
        <DotLoader />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MainAppLoader;
