import styles from '@/styles/Main/Home/Home.module.scss';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
interface LayoutProps {
  children: ReactNode;
}

const UnAuthorizedLayout = ({ children }: LayoutProps) => {
  const router = useRouter();
  return (
    <div className={styles.mainHomecontainer}>
      <MainHeader />
      <div>{children}</div>
      {!(
        router.pathname.includes('login') ||
        router.pathname.includes('register')
      ) && <MainFooter />}
    </div>
  );
};

export default UnAuthorizedLayout;
