import AuthorizedLayout from '@/Components/Layout/AuthorizedLayout';
import UnAuthorizedLayout from '@/Components/Layout/UnAuthorizedLayout';
import MainAppLoader from '@/Components/Loaders/MainAppLoader';
import '@/styles/globals.scss';
import { MobileProvider } from '@/utils/DetectMobileContext';
import { LoadingProvider, useLoading } from '@/utils/LoadingContext';
import { ModalProvider } from '@/utils/ModalContext';
import createEmotionCache from '@/utils/createEmotionCache';
import theme from '@/utils/theme';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SessionProvider, useSession } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
function loadGoogleMapsScript() {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCshoXmzBdA1cjrIgvxooXL_npkvmggoV0&libraries=places&v=weekly&callback=Function.prototype`;
  script.async = true;
  document.body.appendChild(script);
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { session, ...restOfPageProps } = pageProps;
  // useEffect(() => {
  //   loadGoogleMapsScript();
  // }, []);
  const breadcrumbData = [
    {
      position: 1,
      name: 'Price Insights in dubai',
      item: 'https://brokerterminal.com/projects?keyword=Dubai&keyType=location',
    },
    {
      position: 2,
      name: 'ROI Search Dubai',
      item: 'https://brokerterminal.com/dubai/roi-data?keyword=Dubai&keyType=location',
    },
    {
      position: 3,
      name: 'Dubai Sold Property Prices',
      item: 'https://brokerterminal.com/dubai/trends',
    },
    {
      position: 4,
      name: 'Dubai Rental Rates',
      item: 'https://brokerterminal.com/dubai/rental-trends',
    },
  ];
  const jsonLdData = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbData.map((item, index) => ({
      '@type': 'ListItem',
      position: item.position,
      name: item.name,
      item: item.item,
    })),
  };

  // Convert the JSON-LD object to a string
  const jsonLdString = JSON.stringify(jsonLdData);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <DefaultSeo
        title="Broker Terminal Your Dubai Real Estate Hub"
        description={`Welcome to Broker Terminal - Your Premier Source for Dubai Real Estate. Explore property value, market trends, and expert insights for smart investments in Dubai's dynamic real estate market.`}
        canonical={'https://brokerterminal.com'}
        defaultTitle="Broker Terminal Your Dubai Real Estate Hub"
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: 'https://brokerterminal.com',

          title: 'Broker Terminal Your Dubai Real Estate Hub',
          description: `Welcome to Broker Terminal - Your Premier Source for Dubai Real Estate. Explore property value, market trends, and expert insights for smart investments in Dubai's dynamic real estate market.`,
          images: [
            {
              url: 'https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/btlogo/bt_logo.svg',
              width: 800,
              height: 600,
              alt: 'BrokerTerminal',
              type: 'image/jpeg',
            },
          ],
          siteName: 'Brokerterminal',
        }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content:
              'Dubai Real Estate,Property Investment,ROI in Real Estate,Broker Terminal,Real Estate News',
          },
        ]}
        twitter={{
          handle: '@brokerterminal',
          site: '@brokerterminal.com',
          cardType: 'summary_large_image',
        }}
      />
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <SessionProvider session={session}>
          {/* Render the below layout based on authenticated or unauthenticaed */}
          <LoadingProvider>
            <MainAppLoader />
            <MainApp>
              <Component {...restOfPageProps} />
            </MainApp>
          </LoadingProvider>
        </SessionProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

const MainApp = ({ children }: any) => {
  const { data, status } = useSession();
  const router = useRouter();
  const { setLoadingState } = useLoading();
  useEffect(() => {
    const handleStart = (url: String) => {
      !url.includes('#') && url !== router.pathname
        ? setLoadingState(true)
        : setLoadingState(false);
    };
    const handleComplete = () => setLoadingState(false);
    router.events.on('hashChangeStart', handleStart);
    router.events.on('routeChangeStart', handleStart);

    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);
  if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', () => setLoadingState(true));
    window.addEventListener('load', () => setLoadingState(false));
  }
  return status === 'authenticated' ? (
    <AuthorizedLayout>
      <MobileProvider>
        <ModalProvider>{children}</ModalProvider>
      </MobileProvider>
    </AuthorizedLayout>
  ) : status === 'unauthenticated' ? (
    <UnAuthorizedLayout>
      <MobileProvider>
        <ModalProvider>{children}</ModalProvider>
      </MobileProvider>
    </UnAuthorizedLayout>
  ) : (
    <MainAppLoader />
  );
};
